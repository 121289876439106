<template>
  <div>
    <el-row
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="18"
    >
      <el-col style="text-align: left;">
        <span style="font-size: 40px; color: red; font-weight: bold; padding-bottom: 8px">F</span>
        <span style="font-size: 30px; color: red; font-weight: bold; padding: 0px">OST</span>
        <span style="font-size: 38px; font-weight: bold; padding-bottom: 7px">CRM</span>
      </el-col>
    </el-row>
  </div>
</template>
