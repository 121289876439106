<template>
  <div>
    <el-row align="top" :gutter="20">
      <el-col :span="12">
        <el-row align="middle">
          <el-col :span="20">
            <h3>报告服务管理</h3>
          </el-col>
          <el-col
            :span="4"
            style="text-align: right"
          >
            <el-button
              type="primary"
              :disabled="products_full"
              @click="onNewProduct()"
            >新报告开通</el-button>
          </el-col>
          <el-table
            :data="products"
            border
            :default-sort="{ prop: 'name', order: 'descending' }"
            style="width: 100%"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :key="Math.random()"
          >
            <el-table-column
              label="产品名称"
              prop="name"
            >
            </el-table-column>
            <el-table-column
              label="开始日期"
              prop="start"
            >
            </el-table-column>
            <el-table-column
              label="截止日期"
              prop="end"
            >
            </el-table-column>
            <el-table-column label="操作" width="140">
              <template #default="scope">
                <el-button
                  size="small"
                  type="primary"
                  @click="onEditProduct(scope.row.id)"
                >
                  编辑
                </el-button>
                <el-popconfirm
                  title="你确定要删除该产品吗?"
                  @confirm="onDeleteProduct(scope.row.id)"
                >
                  <template #reference>
                    <el-button
                      size="small"
                      type="danger"
                    >删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row align="middle">
          <el-col :span="20">
            <h3>IP地址管理</h3>
          </el-col>
          <el-col
            :span="4"
            style="text-align: right"
          >
            <el-button
              type="primary"
              @click="onNewIP()"
            >新IP开通</el-button>
          </el-col>
        </el-row>
        <el-table
          :data="ips"
          border
          :default-sort="{ prop: 'name', order: 'descending' }"
          style="width: 100%"
          :header-cell-style="{background:'#eef1f6',color:'#606266'}"
          :key="Math.random()"
        >
          <el-table-column
            label="开始段"
            prop="ip_begin"
          />
          <el-table-column
            label="结束段"
            prop="ip_end"
          />
          <el-table-column label="操作"  width="140">
            <template #default="scope">
              <el-button
                size="small"
                type="primary"
                @click="onEditIP(scope.row.id)"
              >
                编辑
              </el-button>
              <el-popconfirm
                title="你确定要删除该条IP吗?"
                @confirm="onDeleteIP(scope.row.id)"
              >
                <template #reference>
                  <el-button
                    size="small"
                    type="danger"
                  >删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
      v-model="product_visible"
      title="产品"
      width="30%"
    >
      <el-form label-width="120px">
        <el-form-item label="产品">
          <el-select
            :disabled="source_disabled"
            v-model="product.name"
          >
            <el-option
              v-for="item in source"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="product.start"
            type="date"
            placeholder="开始日期"
          />
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="product.end"
            type="date"
            placeholder="结束日期"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="product_visible = false">关闭</el-button>
          <el-button
            type="primary"
            @click="onProductSubmit"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="ip_visible"
      title="ip"
      width="30%"
    >
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="IP开始">
              <el-input v-model="ip.ip_begin" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP结束">
              <el-input v-model="ip.ip_end" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ip_visible = false">关闭</el-button>
          <el-button
            type="primary"
            @click="onIPSubmit"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import GlobalFunc from "@/libs/GlobalFunc.vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import qs from "qs";
export default {
  data() {
    var product_visible = false;
    var ip_visible = false;
    var source = [];
    var products = [];
    var product = { start: "", end: "" };
    var ips = [];
    var ip = { ip_begin: "", ip_end: "" };
    var company_id = 0;
    var source_disabled = true;
    var products_full = false;
    var ip_new = false;
    return {
      products,
      source,
      company_id,
      ips,
      product_visible,
      ip_visible,
      ip,
      ip_new,
      product,
      source_disabled,
      products_full,
    };
  },
  methods: {
    // 刷新
    refresh(msg) {
      ElMessage.success(msg);
      this.getProductList(this.company_id);
      this.product_visible = false;
      this.ip_visible = false;
    },
    onNewProduct() {
      this.source_disabled = false;
      this.product = { name: "", start: "2009-01-01", end: "" };
      this.product_visible = true;
    },
    // 删除报告库使用权限
    async onDeleteProduct(id) {
      this.product = this.genProduct(id);
      var url = "api/company/" + this.company_id + "/product_delete";
      var response = await axios.post(url, qs.stringify(this.product));
      if (response.status == 204) {
        this.refresh("删除成功");
      }
    },
    // 新增/更新报告库使用期限
    async onProductSubmit() {
      if (Date.parse(this.product.start) > Date.parse(this.product.end)) {
        ElMessage.error("结束日期不能小于开始日期");
      } else if (this.product.start == null || this.product.end == null) {
        ElMessage.error("日期不能为空");
      } else {
        this.product.start = GlobalFunc.dateFormat(
          "YYYY-mm-dd",
          new Date(this.product.start)
        );
        this.product.end = GlobalFunc.dateFormat(
          "YYYY-mm-dd",
          new Date(this.product.end)
        );
        var url = "";
        var response = "";
        if (this.source_disabled) {
          url = "api/company/" + this.company_id + "/product_update";
          response = await axios.post(url, qs.stringify(this.product));
          if (response.status == 200) {
            this.refresh("更新成功");
          }
        } else {
          url = "api/company/" + this.company_id + "/product_add";
          response = await axios.post(url, qs.stringify(this.product));
          if (response.status == 201) {
            this.refresh("新增成功");
          }
          if (response.status == 200) {
            ElMessage.error(response.data.msg);
          }
        }
      }
    },
    // 取出编辑产品
    genProduct(id) {
      for (var i = 0; i < this.products.length; i++) {
        if (id == this.products[i].id) {
          return this.products[i];
        }
      }
    },
    // 编辑产品
    onEditProduct(id) {
      this.source_disabled = true;
      this.product = this.genProduct(id);
      this.product_visible = true;
    },
    onNewIP() {
      this.ip_new = true;
      this.ip = { ip_begin: "", ip_end: "" };
      this.ip_visible = true;
    },
    onEditIP(id) {
      this.ip_new = false;
      for (var i = 0; i < this.ips.length; i++) {
        if (this.ips[i].id == id) {
          this.ip = this.ips[i];
          break;
        }
      }
      this.ip_visible = true;
    },
    async onDeleteIP(id) {
      var ip = { id: id };
      var url = "api/company/" + this.company_id + "/ip_delete";
      var response = await axios.post(url, qs.stringify(ip));
      if (response.status == 204) {
        this.refresh("删除成功");
      }
    },
    async onIPSubmit() {
      if (this.ip.ip_begin == "" || this.ip.ip_end == "") {
        ElMessage.error("IP地址不能为空");
      } else {
        var url = "";
        if (this.ip_new) {
          url = "api/company/" + this.company_id + "/ip_add";
        } else {
          url = "api/company/" + this.company_id + "/ip_update";
        }
        var response = await axios.post(url, qs.stringify(this.ip));
        if (response.status == 200) {
          this.refresh("更新成功");
        }
        if (response.status == 201) {
          this.refresh("新增成功");
        }
      }
    },
    // 限定每个产品只能开通一个
    setSourceDisable() {
      var exist_count = 0;
      for (var i = 0; i < this.source.length; i++) {
        this.source[i].disabled = false;
        for (var j = 0; j < this.products.length; j++) {
          if (this.products[j].name == this.source[i].name) {
            this.source[i].disabled = true;
            exist_count += 1;
            break;
          }
        }
      }
      if (exist_count >= this.source.length) {
        this.products_full = true;
      } else {
        this.products_full = false;
      }
    },
    // 获取报告类型名称
    getSource(id) {
      for (var i = 0; i < this.source.length; i++) {
        if (this.source[i].id == id) {
          return [this.source[i].name, this.source[i].id];
        }
      }
      return ["内部WORD", 7];
    },
    // 生成产品列表
    genProductList(result) {
      this.products = [];
      for (var i = 0; i < result.length; i++) {
        var source = this.getSource(result[i].source);
        this.products.push({
          id: result[i].id,
          name: source[0],
          source_id: source[1],
          start: result[i].start,
          end: result[i].end,
        });
      }
      this.setSourceDisable();
    },
    async getProductList(id) {
      var url = "api/company/" + id + "/product";
      var response = await axios.get(url);
      this.genProductList(response.data.results);
      this.getIPList(id);
    },
    async getIPList(id) {
      var url = "api/company/" + id + "/ip";
      var response = await axios.get(url);
      this.ips = response.data.results;
    },
  },
  mounted() {
    this.source = [];
    if (GlobalVars.source) {
      for (var i = 0; i < GlobalVars.source.length; i++) {
        if (!GlobalVars.source[i].fost_only) {
          this.source.push(GlobalVars.source[i]);
        }
      }
    }
    this.company_id = GlobalVars.company_id;
    this.getProductList(this.company_id);
  },
};
</script>