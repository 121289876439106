<template>
  <div :class="lg_enough?'lg_css':'sm_css'">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span style="font-size: 40px; color: red; font-weight: bold; padding-bottom: 8px">F</span>
        <span style="font-size: 30px; color: red; font-weight: bold; padding: 0px">OST</span>
        <span style="font-size: 38px; font-weight: bold; padding-bottom: 7px">CRM</span>
        </div>
      </template>
      <el-form
        :model="form"
        label-width="60px"
        style="max-width: 300px"
      >
        <el-form-item label="用户名">
          <el-input
            v-model="form.username"
            @keyup.enter="onSubmit"
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="form.pwd"
            type="password"
            placeholder="请输入密码"
            show-password
            @keyup.enter="onSubmit"
          />
        </el-form-item>
        <el-form-item>
          <div style="text-align: right; width: 100%">
            <el-button
              type="primary"
              @click="onSubmit"
            >登录</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-divider />
      <p>
        无法登录?
        <a href="mailto:10534404@qq.com">请联系技术人员</a>
      </p>
    </el-card>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import qs from "qs";
import { ElMessage } from "element-plus";
import GlobalVars from "@/components/GlobalVars.vue";
const lg_enough = ref(true);
var token=''
export default {
  mounted() {
    lg_enough.value = document.body.clientWidth >= 992;
    token=this.getToken();
  },
  setup() {
    const form = reactive({
      username: "",
      pwd: "",
    });
    let router = useRouter();
    let getToken = async () => {
      let url = "/api/token";
      let response = await axios.get(url);
      return response.data.token;
    };
    let route = useRoute();
    if (route.params.msg !== undefined && route.params.msg.length > 0) {
      ElMessage.error(route.params.msg);
    }
    let login = async () => {
      if (form.username === "" || form.pwd === "") {
        ElMessage.error("请输入用户名和密码");
        return;
      }
      let url = "/api/login";
      let headers = {
        "X-CSRFToken": token,
      };
      let response = await axios.post(url, qs.stringify(form), {
        headers: headers,
      });
      let result = response.data.result;
      if (result) {
        localStorage.setItem("Authorization", true);
        GlobalVars.user_id = response.data.u_id;
        router.push("/main");
      } else {
        ElMessage.error(response.data.msg);
      }
    };
    const onSubmit = () => {
      login();
    };
    return {
      onSubmit,
      form,
      lg_enough,
      getToken,
    };
  },
};
</script>
<style>
.card-header {
  display: flex;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 400px;
}
.lg_css {
  display: flex;
  justify-content: center;
  padding: 200px;
}
.sm_css {
  display: flex;
  justify-content: center;
  padding: 50px;
}
</style>