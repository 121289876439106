<template>
  <div>
    <el-row
      style="padding-bottom: 20px;"
      :gutter="20"
    >
      <!-- <el-col :span="2">
        <el-switch
          inactive-text="显示全部"
          v-model="view_all_company"
          v-on:change="getCompanyList"
        />
      </el-col> -->
      <el-col
        :lg="3"
        :md="6"
      >
        <span
          class="el-switch__label el-switch__label--left is-active"
          style="cursor:auto;padding-right:5px"
        >销售:</span>
        <el-select
          style="width:100px"
          v-model="salesman_sel"
          clearable
          placeholder="全部"
          v-on:change="getCompanyList(0)"
        >
          <el-option
            v-for="item in salesman"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col
        :lg="3"
        :md="6"
      >
        <span
          class="el-switch__label el-switch__label--left is-active"
          style="cursor:auto;padding-right:5px"
        >状态:</span>
        <el-select
          style="width:100px"
          v-model="status_sel"
          clearable
          placeholder="全部"
          v-on:change="getCompanyList(0)"
        >
          <el-option
            v-for="item in status"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-col>
      <el-col
        :lg="3"
        :md="6"
      >
        <span
          class="el-switch__label el-switch__label--left is-active"
          style="cursor:auto;padding-right:5px"
        >类别:</span>
        <el-select
          style="width:100px"
          v-model="category_sel"
          clearable
          placeholder="全部"
          v-on:change="getCompanyList(0)"
        >
          <el-option
            v-for="item in category"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col
        :lg="4"
        :md="6"
        :offset-lg="8"
        :offset-md="3"
      >
        <el-input
          placeholder="请输入单位名称"
          v-model="search_txt"
          clearable
          v-on:clear="getCompanyList(0)"
          @keyup.enter="getCompanyList(0)"
        >
          <template #append>
            <el-button v-on:click="getCompanyList(0)">
              <el-icon>
                <Search />
              </el-icon>
            </el-button>
          </template>
        </el-input>
      </el-col>

    </el-row>
    <el-table
      border
      :default-sort="{ prop: 'name', order: 'descending' }"
      :data="company_data"
      style="width: 100%;"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :key="Math.random()"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        prop="name"
        label="单位名称"
      >
      </el-table-column>
      <el-table-column
        prop="last_record"
        label="最新联系时间"
        min-width="40"
      >
        <template #default="scope">
          {{record_time_formatter(scope.row)}}
          <el-icon
            v-if="expired(scope.row)"
            :size="18"
            color="#ff0000"
          >
            <WarningFilled />
          </el-icon>
        </template>
      </el-table-column>
      <el-table-column
        prop="last_record"
        label="最新联系内容"
        :formatter="record_msg_formatter"
      />
      <el-table-column
        prop="status"
        label="商务状态"
        min-width="30"
        :formatter="status_formatter"
      />
      <el-table-column
        prop="category"
        label="类别"
        min-width="30"
        :formatter="category_formatter"
      />
      <el-table-column
        prop="mail_count"
        label="邮"
        min-width="20"
      />
      <el-table-column
        prop="call_count"
        label="TEL"
        min-width="20"
        cell-class-name
      />
      <el-table-column
        prop="wechat_count"
        label="微"
        min-width="20"
      />
      <el-table-column
        prop="lib"
        label="LIB"
        min-width="25"
        :formatter="lib_formatter"
      />
      <el-table-column
        prop="salesman"
        label="销售"
        min-width="25"
        :formatter="salesman_formatter"
      />
      <el-table-column
        prop="comment"
        label="最新备注"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="25"
      >
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            :disabled="!scope.row.edit_enabled"
            @click="onOpenDetail(scope.row.id,scope.row.name)"
          >详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row align="middle">
      <el-col :span="16">
        <el-pagination
          background
          :current-page="currentpage"
          layout="prev, pager, next"
          :total="count"
          :page-size="size"
          @update:current-page="handleCurrentChange"
          style="padding-top:20px"
        />
      </el-col>
      <el-col
        :span="4"
        :offset="4"
        style="text-align: right"
      >
        <el-button
          type="primary"
          @click="onNewCompany"
        >新增客户</el-button>
      </el-col>
    </el-row>
    <el-dialog
      v-model="new_company_visible"
      title="新增公司"
      width="50%"
    >
      <el-form
        :model="newCompany"
        label-width="120px"
        :rules="rules"
        ref="company_info"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="公司全称"
              prop="name"
            >
              <el-input v-model="newCompany.name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公司简称"
              prop="short_name"
            >
              <el-input v-model="newCompany.short_name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="商务状态"
              prop="status"
            >
              <el-select
                v-model="newCompany.status"
                class="m-2"
                size="large"
                disabled
              >
                <el-option
                  v-for="item in status"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公司分类"
              prop="category"
            >
              <el-select
                v-model="newCompany.category"
                class="m-2"
                placeholder="请选择分类"
                size="large"
              >
                <el-option
                  v-for="item in category"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="地址">
              <el-input v-model="newCompany.address" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编">
              <el-input v-model="newCompany.zip" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="网址">
              <el-input v-model="newCompany.website" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="联系电话"
              prop="tel"
            >
              <el-input v-model="newCompany.tel" />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item
              label="公司备注"
              prop="comment"
            >
              <el-input
                type="textarea"
                v-model="newCompany.comment"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="公司概况"
              prop="desc"
            >
              <el-input
                type="textarea"
                v-model="newCompany.desc"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="8">
            <el-form-item
              label="数据库账号"
              prop="account"
            >
              <el-input v-model="newCompany.account" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="数据库密码"
              prop="password"
            >
              <el-input v-model="newCompany.password" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="并发"
              prop="lib_limit"
            >
              <el-input v-model="newCompany.lib_limit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="邮件推送">
              <el-switch v-model="newCompany.mail_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数据库开通">
              <el-switch v-model="newCompany.lib_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="微信群推送">
              <el-switch v-model="newCompany.wechat_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电话会议">
              <el-switch v-model="newCompany.call_enabled" />
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="new_company_visible = false">关闭</el-button>
          <el-button
            type="primary"
            @click="onNewCompanySubmit"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import GlobalVars from "@/components/GlobalVars.vue";
import { ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { emitter } from "@/libs/bus.ts";
import qs from "qs";
export default {
  components: { Search },
  data() {
    var company_data = [];
    var count = 0;
    var currentpage = 1;
    const size = 10;
    var newCompany = {
      mail_enabled: false,
      lib_enabled: false,
      wechat_enabled: false,
      call_enabled: false,
      lib_limit: 1,
      status: 5,
    };
    var new_company_visible = false;
    var status = [];
    var status_sel = "";
    var category = [];
    var category_sel = "";
    var salesman = [];
    var salesman_sel = "";
    var view_all_company = true;
    var search_txt = "";
    var rules = {
      name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      status: [{ required: true, message: "请选择商务状态", trigger: "blur" }],
      short_name: [
        { required: true, message: "请输入公司简称", trigger: "blur" },
      ],
      category: [
        { required: true, message: "请选择公司分类", trigger: "blur" },
      ],
      tel: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      comment: [{ required: true, message: "请输入公司备注", trigger: "blur" }],
      desc: [{ required: true, message: "请输入公司概况", trigger: "blur" }],
      // account: [
      //   { required: true, message: "请输入数据库账号", trigger: "blur" },
      // ],
      // password: [
      //   { required: true, message: "请输入数据库密码", trigger: "blur" },
      // ],
      // lib_limit: [
      //   { required: true, message: "请输入数据库并发", trigger: "blur" },
      // ],
    };
    return {
      company_data,
      count,
      currentpage,
      size,
      newCompany,
      new_company_visible,
      status,
      status_sel,
      category,
      category_sel,
      salesman,
      salesman_sel,
      view_all_company,
      search_txt,
      rules,
    };
  },
  methods: {
    // 计算沟通时间到期提醒
    expired(row) {
      let today = new Date();
      let last_connect = new Date(row.last_record.modified);
      if (row.serviced != null) {
        let s = new Date(row.serviced);
        let x = (today - s) / 1000 / 60 / 60;
        let y = (today - last_connect) / 1000 / 60 / 60;
        // console.log(row.name);
        // console.log("x:" + x);
        // console.log("record:" + row.last_record.modified);
        // console.log("y:" + y);
        // console.log("-----------------------------------");
        switch (row.status) {
          case 1:
            if ((today - last_connect) / 1000 > 30 * 24 * 60 * 60) {
              return true;
            } else {
              return false;
            }
          case 0:
          case 4:
            if (x > 24 && x < 48 && y < x) {
              return false;
            }
            if (x > 60 && x < 120 && y < x) {
              return false;
            }
            if (x > 144 && x < 240 && y < x) {
              return false;
            }
            if (x > 240 && y > 336) {
              return true;
            }
            if (row.last_record.modified == undefined) {
              return true;
            }
            if (y > x) {
              return true;
            }
        }
      } else {
        return false;
      }
    },
    tableRowClassName(row) {
      switch (row.row.status) {
        case 5:
          return "wait-row";
        case 2:
          return "refuse-row";
        case 0:
          return "contact-row";
        case 1:
          return "try-row";
        case -1:
          return "quote-row";
        case 3:
          return "contract-row";
        case 4:
          return "success-row";
      }
    },
    init() {
      this.status = GlobalVars.status;
      this.category = GlobalVars.category;
      this.salesman_sel = GlobalVars.user_id;
      this.salesman = GlobalVars.salesman;
      this.getCompanyList(0);
    },
    // 新建立公司
    onNewCompany() {
      this.new_company_visible = true;
    },
    async companySubmit() {
      let url = "api/company/add";
      let response = await axios.post(url, qs.stringify(this.newCompany));
      if (response.status === 201) {
        this.new_company_visible = false;
        ElMessage.success("添加记录成功");
        this.newCompany = {
          mail_enabled: false,
          lib_enabled: false,
          wechat_enabled: false,
          call_enabled: false,
          lib_limit: 1,
          status: 5,
        };
        this.getCompanyList(0);
      } else {
        ElMessage.error(response.data.msg);
      }
    },
    // 提交新公司
    onNewCompanySubmit() {
      this.$refs.company_info.validate((valid) => {
        if (valid) {
          this.companySubmit();
        }
      });
    },
    // 公司详情
    onOpenDetail(id, name) {
      this.$emit("open-detail", id, name);
    },
    // 格式化沟通记录
    record_msg_formatter(row) {
      var record_msg = String(row.last_record.message);
      if (record_msg.length > 30) {
        record_msg = record_msg.substring(0, 30) + "...";
      }
      if (record_msg == "undefined") {
        return "-";
      }
      return record_msg;
    },
    // 格式化时间
    record_time_formatter(row) {
      var record_time = String(row.last_record.modified).substring(0, 10);
      if (record_time == "undefined") {
        return "-";
      }
      return record_time;
    },
    // 格式化状态
    status_formatter(row) {
      for (var i = 0; i < this.status.length; i++) {
        if (row.status == this.status[i].code) {
          return this.status[i].name;
        }
      }
    },
    // 格式化分类
    category_formatter(row) {
      for (var i = 0; i < this.category.length; i++) {
        if (row.category == this.category[i].id) {
          return this.category[i].name;
        }
      }
    },
    // 格式化销售员
    salesman_formatter(row) {
      return row.salesman.full_name;
    },
    // 格式化数据库
    lib_formatter(row) {
      if (row.lib_enabled == 1) {
        return "正常";
      } else {
        return "停用";
      }
    },

    // 获取公司列表
    async getCompanyList(t) {
      var params = new URLSearchParams();
      if (t == 1) {
        params.appendIfExists("page", this.currentpage);
      } else {
        params.appendIfExists("page", 1);
        this.currentpage = 1;
      }
      params.appendIfExists("all", this.view_all_company);
      if (this.status_sel !== "") {
        params.appendIfExists("status", this.status_sel);
      }
      if (this.category_sel !== "") {
        params.appendIfExists("category", this.category_sel);
      }
      if (this.salesman_sel !== "") {
        params.appendIfExists("salesman", this.salesman_sel);
      }
      params.appendIfExists("search", this.search_txt);
      var paramsString = params.toString();
      var url = "api/company?" + paramsString;
      var response = await axios.get(url);
      this.company_data = response.data.results;
      this.count = response.data.count;
    },
    // 分页监测
    handleCurrentChange(val) {
      this.currentpage = val;
      this.getCompanyList(1);
    },
  },
  mounted() {
    emitter.on("config-complete", this.init);
    this.init();
  },
};
</script>
<style>
.el-table .wait-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .refuse-row {
  --el-table-tr-bg-color: var(--el-color-info-light-3);
}
.el-table .contact-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
.el-table .try-row {
  --el-table-tr-bg-color: var(--el-color-success-light-7);
}
.el-table .quote-row {
  --el-table-tr-bg-color: var(--el-color-success-light-5);
}
.el-table .contract-row {
  --el-table-tr-bg-color: var(--el-color-success-light-3);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-primary-light-3);
}
.el-table__body tr:hover > td {
  background-color: #fda88b !important;
}

.el-table__body tr.current-row > td {
  background-color: #fda88b !important;
}
</style>