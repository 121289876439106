import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainView from  '../views/MainView.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta:{
      title: '请登录'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: MainView,
    meta:{
      title: 'FOST CRM'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path === '/') {
    next();
  } else {
    let token = localStorage.getItem('Authorization');
    if (token === null || token === '') {
      next('/');
    } else {
      next();
    }
  }
});
export default router
