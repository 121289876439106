<template>
  <el-row align="middle">
    <el-col :span="20">
      <h3>成交记录</h3>
    </el-col>
    <el-col
      :span="4"
      style="text-align: right"
    >
      <el-button
        type="primary"
        @click="onNewDeal()"
      >新成交记录</el-button>
    </el-col>
  </el-row>
  <el-table
    :data="deals"
    border
    :default-sort="{ prop: 'name', order: 'descending' }"
    style="width: 100%"
    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
    :key="Math.random()"
  >
    <el-table-column
      label="年份"
      prop="year"
    />
    <el-table-column
      label="产品"
      prop="products"
    >
      <template #default="scope">
        <ul style="list-style-type:none">
          <li
            v-for="item in scope.row.products"
            :key="item.index"
          >
            {{ item }}
          </li>
        </ul>
      </template>
    </el-table-column>
    <el-table-column
      label="单价"
      prop="price"
    >
      <template #default="scope">
        <ul style="list-style-type:none">
          <li
            v-for="item in scope.row.price"
            :key="item.index"
          >
            {{ item }}
          </li>
        </ul>
      </template>
    </el-table-column>

    <el-table-column
      label="客户代表"
      prop="customer"
    />
    <el-table-column
      label="总价"
      prop="total"
    />
    <el-table-column
      label="成交日期"
      prop="dday"
    />
    <el-table-column
      label="备注"
      prop="comment"
    />
    <el-table-column label="操作" >
      <template #default="scope">
        <el-popconfirm
          title="你确定要删除该条记录吗?"
          @confirm="onDeleteDeal(scope.row.ids)"
        >
          <template #reference>
            <el-button
              size="small"
              type="danger"
            >删除</el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog
    v-model="deal_visible"
    :title="deal_title"
    width="600px"
  >
    <el-form label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="deal.deal_date"
              type="date"
              placeholder="请选择日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人">
            <el-input v-model="deal.customer" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="成交产品">
            <ul style="list-style-type:none">
              <li
                v-for="item in source"
                :key="item.id"
                style="padding:10px"
              >
                <el-row>
                  <el-col :span="10">
                    <el-checkbox
                      v-model="item.selected"
                      :label=item.name
                    />
                  </el-col>
                  <el-col :span="14">
                    <el-row>
                      <el-col :span="8">单价:</el-col>
                      <el-col :span="16">
                        <el-input v-model="item.price"></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="deal.comment"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deal_visible = false">关闭</el-button>
        <el-button
          type="primary"
          @click="onDealSubmit"
        >提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import GlobalFunc from "@/libs/GlobalFunc.vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import qs from "qs";
export default {
  data() {
    var source = [];
    var deal_visible = false;
    var company_id = 0;
    var deal_title = "新成交记录";
    var deal = { source: "", price: "", deal_date: "", customer: "", ids: [] };
    var deals = [
    ];
    return {
      deal_title,
      source,
      company_id,
      deals,
      deal,
      deal_visible,
    };
  },
  methods: {
    genSource(products, price, ids) {
      for (var i = 0; i < products.length; i++) {
        for (var j = 0; j < this.source.length; j++) {
          if (this.source[j].name == products[i]) {
            this.source[j].selected = true;
            this.source[j].price = price[i];
            this.source[j].deal_id = ids[i];
            break;
          }
        }
      }
    },
    async onDeleteDeal(ids) {
      var url = "api/company/" + this.company_id + "/deal_delete";
      let response = await axios.post(url, ids);
      if (response.status == 204) {
        ElMessage.success("删除成功");
        this.getDealList();
      } else {
        ElMessage.error(response.data.msg);
      }
    },
    // 刷新
    refresh(msg) {
      ElMessage.success(msg);
      this.getDealList();
      this.deal_visible = false;
    },
    async onDealSubmit() {
      var url = "";
      var i = 0;
      var allAxios = [];
      if (this.deal_title == "新成交记录") {
        url = "api/company/" + this.company_id + "/deal_add";
        for (i = 0; i < this.source.length; i++) {
          if (this.source[i].selected) {
            this.deal.source = this.source[i].id;
            this.deal.price = this.source[i].price;
            this.deal.deal_date = GlobalFunc.dateFormat(
              "YYYY-mm-dd",
              new Date(this.deal.deal_date)
            );
            allAxios.push(axios.post(url, qs.stringify(this.deal)));
          }
        }
      }
      axios.all(allAxios).then(
        axios.spread(() => {
          this.refresh("新增成功");
        })
      );
    },
    resetSource() {
      this.source = GlobalVars.source;
      for (var i = 0; i < this.source.length; i++) {
        this.source[i].selected = false;
        this.source[i].price = 0;
      }
    },
    onNewDeal() {
      this.deal_title = "新成交记录";
      this.deal_visible = true;
    },
    // 获取集合分项
    getItemList(list, t) {
      var result = [];
      var i = 0;
      if (t == "source") {
        for (i = 0; i < list.length; i++) {
          for (var j = 0; j < this.source.length; j++) {
            if (list[i][t] == this.source[j].id) {
              result.push(this.source[j].name);
              break;
            }
          }
        }
        return result;
      } else {
        for (i = 0; i < list.length; i++) {
          result.push(list[i][t]);
        }
        return result;
      }
    },
    // 获取总价
    getTotal(list) {
      var total = 0;
      for (var i = 0; i < list.length; i++) {
        total += list[i].price;
      }
      return total;
    },
    getIds(list) {
      var ids = [];
      for (var i = 0; i < list.length; i++) {
        ids.push(list[i].id);
      }
      return ids;
    },
    // 交易列表
    async getDealList() {
      var url = "api/company/" + this.company_id + "/deal";
      var response = await axios.get(url);
      this.deals = [];

      var temp = GlobalFunc.groupBy(response.data.results, (link) => {
        return link.deal_date;
      });
      for (var key in temp) {
        var obj = {
          year: key.substring(1, 5),
          dday: key.replace('"', "").replace('"', ""),
          customer: temp[key][0].customer,
          price: this.getItemList(temp[key], "price"),
          products: this.getItemList(temp[key], "source"),
          total: this.getTotal(temp[key]),
          comment: temp[key][0].comment,
          ids: this.getIds(temp[key]),
        };
        this.deals.push(obj);
      }
    },
  },
  mounted() {
    this.resetSource();
    this.company_id = GlobalVars.company_id;
    this.getDealList();
  },
};
</script>