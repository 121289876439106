<template>
  <el-card style="width:1000px">
    <template #header>
      <div>
        <span>发送邮件</span>
      </div>
    </template>
    <div>
      <el-row
        style="padding-bottom:20px"
        align="middle"
      >
        <el-col :span="2">
          <h5>发送类型</h5>
        </el-col>
        <el-col :span="22">
          <el-radio-group v-model="mail.source">
            <el-radio-button
              v-for="item in source"
              :label="item.id"
              :key="item.id"
              border
              size="large"
              @change="onMailTypeChange"
            >{{item.name}}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row style="padding-bottom:20px">
        <el-col :span="2">
          <h5>发送统计</h5>
        </el-col>
        <el-col :span="22">
          <el-table
            :data="summary"
            border
            :default-sort="{ prop: 'name', order: 'descending' }"
            style="width: 200"
            :show-header="false"
            :key="Math.random()"
          >
            <el-table-column
              label="项目"
              prop="name"
            >
            </el-table-column>
            <el-table-column
              label="数量"
              prop="count"
            ></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row style="padding-bottom:20px">
        <el-col>
          <h5>邮件正文</h5>
        </el-col>
        <el-col>
          <el-input
            v-model="mail.content"
            type="textarea"
            rows="8"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-upload
            v-model:file-list="fileList"
            action="api/mail/upload"
            :headers="{'X-CSRFToken':token}"
            :on-error="onUploadError"
            :on-success="onUploadSuccess"
            :limit="1"
          >
            <el-button type="success">点击上传附件</el-button>
            <template #tip>
              <div class="el-upload__tip">
                请上传要发送的文件
              </div>
            </template>
          </el-upload>
        </el-col>
        <el-col
          :span="12"
          style="text-align: right"
        >
          <el-button
            type="primary"
            @click="onSendMail"
          >发送邮件</el-button>
        </el-col>
      </el-row>
    </div>
  </el-card>
  <el-dialog
    v-model="error_visible"
    title='邮件发送失败'
    width="40%"
  >
    <ul>
      <li
        v-for="item in errors"
        :key="item.index"
      >
        <p>{{ item.title }}</p>
        <p>{{ item.reason }}</p>
        <p>以下邮箱未发送:</p>
        <p>{{ item.receivers }}</p>
      </li>
    </ul>
  </el-dialog>
  <el-dialog
    v-model="check_mail_visible"
    title="请检查要发送的邮件"
    width="60%"
  >
    <el-steps
      :active="active"
      align-center
      direction="vertical"
      finish-status="error"
    >
      <el-step
        title="Step 1"
        :description="description_1"
      />
      <el-step
        title="Step 2"
        :description="description_2"
      />
      <el-step
        title="Step 3"
        :description="description_3"
      />
    </el-steps>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="next">{{next_text}}</el-button>
        <el-button @click="check_mail_visible = false">Cancel</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="mail_success"
    width="20%"
  >
    <el-result
      icon="success"
      :title=mail_success_title
    >
      <template #extra>
        <el-button
          type="primary"
          @click="mail_success=false"
        >关闭</el-button>
      </template>
    </el-result>
  </el-dialog>

</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import axios from "axios";
import { ElMessage, ElLoading } from "element-plus";
import qs from "qs";
export default {
  data() {
    var active = 0;
    var next_text = "下一项";
    var mail_success_title = "";
    var description_1 = "";
    var description_2 = "";
    var description_3 = "";
    var check_mail_visible = false;
    var mail_success = false;
    var source = [];
    var fileList = [];
    var mail = { attachment: "", content: "", source: "1" };
    var summary = [
      { name: "公司数", count: 120 },
      { name: "邮箱数", count: 1200 },
    ];
    var token = "";
    var error_visible = false;
    var errors = [];
    return {
      mail_success_title,
      next_text,
      mail_success,
      check_mail_visible,
      description_1,
      description_2,
      description_3,
      active,
      source,
      summary,
      fileList,
      token,
      mail,
      error_visible,
      errors,
    };
  },
  methods: {
    next() {
      this.active++;
      if (this.active > 2) {
        this.next_text = "发送";
      }
      if (this.active > 3) {
        this.check_mail_visible = false;
        this.active = 0;
        this.send();
      }
    },
    async send() {
      var loading = ElLoading.service({
        lock: true,
        text: "Loading",
      });
      var url = "api/mail/send";
      var response = await axios.post(url, qs.stringify(this.mail));
      if (response.status == 200) {
        loading.close();
        let source_idx = -1;
        for (var i = 0; i < this.source.length; i++) {
          if (this.mail.source == this.source[i].id) {
            source_idx = i;
            break;
          }
        }
        if (response.data.status) {
          this.mail_success_title = this.source[source_idx].name + "发送成功";
          this.mail_success = true;
          this.mail.content = this.source[source_idx].content;
          this.mail.attachment = "";
          this.fileList = [];
        } else {
          this.error_visible = true;
          this.errors = response.data.content;
        }
      }
    },
    // 切换邮件报告类型
    async onMailTypeChange() {
      for (var i = 0; i < this.source.length; i++) {
        if (this.mail.source == this.source[i].id) {
          this.mail.content = this.source[i].content;
          break;
        }
      }
      var url = "api/mail/count/" + this.mail.source;
      var response = await axios.get(url);
      this.summary[0].count = response.data.company_count;
      this.summary[1].count = response.data.staff_count;
    },
    // 发送邮件
    async onSendMail() {
      if (this.mail.attachment == "" || this.fileList == []) {
        ElMessage.error("请先上传附件");
        return;
      }
      let source_idx = -1;
      for (var i = 0; i < this.source.length; i++) {
        if (this.mail.source == this.source[i].id) {
          source_idx = i;
          break;
        }
      }
      if (
        this.mail.content == "" ||
        (this.mail.content == this.source[source_idx].content &&
          this.source[source_idx].name.search("WORD") == -1)
      ) {
        ElMessage.error("请填写邮件正文");
        return;
      }
      if (this.mail.attachment.search(this.source[source_idx].name) == -1) {
        if (this.source[source_idx].name.search("WORD") == -1) {
          ElMessage.error("邮件类别与附件不符");
          return;
        }
      }
      if (this.mail.attachment.search("pdf") == -1) {
        if (this.source[source_idx].name.search("WORD") == -1) {
          ElMessage.error("附件应上传pdf格式文件");
          return;
        }
      }
      this.description_1 = "发送邮件类别:" + this.source[source_idx].name;
      this.description_2 = "邮件正文:" + this.mail.content;
      this.description_3 = "附件:" + this.mail.attachment;
      this.check_mail_visible = true;
    },
    // 上传失败(系统错误)
    onUploadError() {
      ElMessage.error("上传失败!请联系技术支持");
    },
    // 上传失败/完成
    onUploadSuccess(result) {
      if (result.status == false) {
        ElMessage.error(result.msg);
        this.fileList = [];
      } else {
        this.mail.attachment = result.msg;
      }
    },
    // 获取token
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  mounted() {
    this.token = this.getCookie("csrftoken");
    this.source = GlobalVars.source;
    this.onMailTypeChange();
  },
};
</script>