<template>
  <div>
    <el-form
      :model="company_detail"
      label-width="100px"
      :rules="rules"
      ref="company_info"
    >
      <el-row type="flex">
        <el-col
          :lg="6"
          :xl="5"
          :md="12"
        >
          <el-form-item
            label="公司全称"
            prop="name"
          >
            <el-input
              disabled
              v-model="company_detail.name"
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="5"
          :md="12"
        >
          <el-form-item label="简称">
            <el-input v-model="company_detail.short_name" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="5"
          :md="12"
        >
          <el-form-item label="地址">
            <el-input v-model="company_detail.address" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item label="邮编">
            <el-input v-model="company_detail.zip" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item label="网址">
            <el-input v-model="company_detail.website" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :lg="6"
          :xl="4"
          :md="12"
        >
          <el-form-item
            label="联系电话"
            prop="tel"
          >
            <el-input v-model="company_detail.tel" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item label="分类">
            <el-select
              disabled
              v-model="company_detail.category"
            >
              <el-option
                v-for="item in category"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item label="销售人员">
            <el-input
              v-model="company_detail.salesman.full_name"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item label="服务开始于">
            <el-input
              v-model="serviced"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="company_detail.lib_enabled"
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item
            label="数据库账号"
            prop="account"
          >
            <el-input v-model="company_detail.account" />
          </el-form-item>
        </el-col>
        <el-col
          v-if="company_detail.lib_enabled"
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-form-item
            label="数据库密码"
            prop="password"
          >
            <el-input v-model="company_detail.password" />
          </el-form-item>
        </el-col>
        <el-col
          v-if="company_detail.lib_enabled"
          :lg="6"
          :xl="2"
          :md="12"
        >
          <el-form-item
            label="数据库并发"
            prop="lib_limit"
          >
            <el-input v-model="company_detail.lib_limit" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :lg="3"
          :xl="2"
          :md="3"
        >
          <el-form-item label="邮件">
            <el-switch v-model="company_detail.mail_enabled" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="3"
          :xl="2"
          :md="3"
        >
          <el-form-item label="数据库">
            <el-switch v-model="company_detail.lib_enabled" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="3"
          :xl="2"
          :md="3"
        >
          <el-form-item label="电话会议">
            <el-switch v-model="company_detail.call_enabled" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="3"
          :xl="2"
          :md="3"
        >
          <el-form-item label="微信小程序">
            <el-switch v-model="company_detail.wechat_enabled" />
          </el-form-item>
        </el-col>
        <el-col
          :lg="12"
          :xl="12"
          :md="12"
        >
          <el-form-item
            label="公司概况"
            prop="desc"
          >
            <el-input
              type="textarea"
              v-model="company_detail.desc"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item
            label="备注"
            prop="comment"
          >
            <el-input
              type="textarea"
              v-model="company_detail.comment"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="space-between">
        <el-col
          :lg="4"
          :xl="4"
          :md="12"
        >
          <el-form-item
            label="目前状态"
            prop="status"
          >
            <el-select v-model="company_detail.status">
              <el-option
                v-for="item in status"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :xl="3"
          :md="12"
        >
          <el-row justify="space-between">
            <el-col :span="6">
              <el-button
                type="success"
                @click="onUpdate"
              >保存</el-button>
            </el-col>
            <el-col :span="6">
              <el-button
                type="danger"
                @click="deleteWarning=true"
              >删除</el-button>
            </el-col>
            <el-col :span="6">
              <el-button
                type="warning"
                @click="transVisble=true"
              >转移</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <LibManager />
    <Deal />
    <el-tabs
      v-model="tab_sel"
      @tab-click="onTabClick"
    >
      <el-tab-pane
        label="沟通记录"
        name="record"
      >
        <record-view v-if="tab_visible.record" />
      </el-tab-pane>
      <el-tab-pane
        label="员工管理"
        name="staff"
      >
        <StaffView v-if="tab_visible.staff" />
      </el-tab-pane>
    </el-tabs>
  </div>
  <el-dialog
    v-model="transVisble"
    title="转移客户"
    width="20%"
    center
  >
    <span style="margin-right:10px">
      转移客户给其他同事
    </span>
    <el-select
      style="width:100px"
      v-model="salesman_sel"
    >
      <el-option
        v-for="item in salesman"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="transVisble = false">取消</el-button>
        <el-button
          type="danger"
          @click="transCustomer"
        >
          确认转移
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="deleteWarning"
    title="警告"
    width="30%"
    center
  >
    <span>
      删除公司会导致此公司所有信息(员工,购买记录,开通记录,数据库账号,沟通记录等)被完全删除,且无法恢复,请慎重考虑删除公司的必要性!
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteWarning = false">取消</el-button>
        <el-button
          type="danger"
          @click="deleteCompany()"
        >
          确认删除
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import RecordView from "./RecordView.vue";
import StaffView from "./StaffManager.vue";
import LibManager from "./LibManager.vue";
import Deal from "./DealRecord.vue";
import axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
export default {
  components: { RecordView, StaffView, LibManager, Deal },
  data() {
    var company_detail = { salesman: {}, category: {}, status: {} };
    var category = [];
    var salesman = [];
    var salesman_sel = "";
    var company_id = 0;
    var status = [];
    var tab_sel = "record";
    var deleteWarning = false;
    var transVisble = false;
    var tab_visible = {
      record: true,
      staff: false,
    };
    var rules = {
      name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      status: [{ required: true, message: "请选择商务状态", trigger: "blur" }],
      tel: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      comment: [{ required: true, message: "请输入公司备注", trigger: "blur" }],
      desc: [{ required: true, message: "请输入公司概况", trigger: "blur" }],
      // account: [
      //   { required: true, message: "请输入数据库账号", trigger: "blur" },
      // ],
      // password: [
      //   { required: true, message: "请输入数据库密码", trigger: "blur" },
      // ],
      // lib_limit: [
      //   { required: true, message: "请输入数据库并发", trigger: "blur" },
      // ],
    };
    return {
      company_detail,
      company_id,
      status,
      tab_sel,
      category,
      tab_visible,
      rules,
      deleteWarning,
      transVisble,
      salesman,
      salesman_sel,
    };
  },
  computed: {
    serviced() {
      if (this.company_detail.serviced != null) {
        return String(this.company_detail.serviced).substring(0, 10);
      } else {
        return "未开始服务";
      }
    },
  },
  methods: {
    // 转移公司
    async transCustomer() {
      this.transVisble = false;
      let url =
        "api/company/" + this.company_id + "/trans/" + this.salesman_sel;
      let response = await axios.post(url);
      if (response.status === 200) {
        ElMessage.success("转移公司成功");
        location.reload()
      }
    },
    //删除公司
    async deleteCompany() {
      this.deleteWarning = false;
      let url = "api/company/" + this.company_id + "/delete";
      let response = await axios.post(url);
      if (response.status === 204) {
        ElMessage.success("删除公司成功");
        location.reload()
      }
    },
    onTabClick(tab) {
      this.tab_sel = tab.props.name;
      switch (this.tab_sel) {
        case "record":
          this.switchTab("record");
          break;
        case "staff":
          this.switchTab("staff");
          break;
      }
    },
    switchTab(tab) {
      for (let key in this.tab_visible) {
        if (key == tab) {
          this.tab_visible[key] = true;
        } else {
          this.tab_visible[key] = false;
        }
      }
    },
    async updateCompany() {
      if (
        this.company_detail.lib_enabled &&
        (this.company_detail.lib_limit == "" ||
          this.company_detail.account == "" ||
          this.company_detail.password == "")
      ) {
        ElMessage.error("开通数据库必须设置相关账号信息");
        return;
      }
      let url = "api/company/" + this.company_id + "/update";
      var response = await axios.post(url, qs.stringify(this.company_detail));
      if (response.status === 200) {
        ElMessage.success("更新成功");
      }
    },
    onUpdate() {
      this.$refs.company_info.validate((valid) => {
        if (valid) {
          this.updateCompany();
        }
      });
    },
    // 格式化销售员
    salesman_formatter(row) {
      return row.salesman_fullname;
    },
    // 获取公司详情
    async getCompanyDetail(id) {
      var url = "api/company/" + id;
      var response = await axios.get(url);
      this.company_detail = response.data;
    },
  },
  mounted() {
    this.company_id = GlobalVars.company_id;
    this.status = GlobalVars.status;
    this.category = GlobalVars.category;
    this.salesman = GlobalVars.salesman;
    this.getCompanyDetail(this.company_id);
  },
};
</script>
