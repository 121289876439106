import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import { ElMessage } from "element-plus";
import dateFormat from './assets/js/common.js'
import 'echarts'
import ECharts from 'vue-echarts'

URLSearchParams.prototype.appendIfExists = function (key, value) {
  if (value !== null && value !== undefined) {
    this.append(key, value)
  }
};
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 截取出csrftoken
let getCookie = function (cookie) {
  let reg = /csrftoken=([\w]+)[;]?/g
  return reg.exec(cookie)[1]
}
// 统一给post添加头请求
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  function (config) {
    // 在post请求前统一添加X-CSRFToken的header信息
    let token = document.cookie;
    if (token && config.method == 'post') {
      config.headers['X-CSRFToken'] = getCookie(token);
    }
    return config;
  },
  function (error) {
    ElMessage.error({ message: '请求超时!' });
    return Promise.reject(error);
  }
)
function genErrorMsg(data) {
  if (typeof (data) == 'string') {
    return data
  }
  var msg = ''
  for (let key in data) {
    msg += key + ":" + data[key]
  }
  return msg
}
//  RESPONSE 响应异常拦截
axios.interceptors.response.use(data => {
  //==============  所有请求完成后都要执行的操作  ==================

  // 第二种方式，仅对200和error状态处理
  if (data.status && data.status == 200 && data.data.status == 'error') {
    ElMessage.error({ message: data.data.msg });
    return;
  } else if (data.status && data.status == 200 && data.data.result === false) {
    let msg = genErrorMsg(data.data.msg)
    ElMessage.error({ message: msg });
    return;
  }
  return data;
}, err => {
  //==============  错误处理  ====================
  if (err && err.response) {
    var msg = ''
    if (typeof (err.response.data) == 'object') {
      msg = genErrorMsg(err.response.data)
    } else {
      msg = err.response.data
    }

    switch (err.response.status) {
      case 400: err.message = '请求错误(400)'; break;
      case 401: err.message = '未授权，请重新登录(401)'; break;
      case 403: err.message = '拒绝访问(403)'; break;
      case 404: err.message = '请求出错(404)'; break;
      case 408: err.message = '请求超时(408)'; break;
      case 500: err.message = '服务器错误(500)'; break;
      case 501: err.message = '服务未实现(501)'; break;
      case 502: err.message = '网络错误(502)'; break;
      case 503: err.message = '服务不可用(503)'; break;
      case 504: err.message = '网络超时(504)'; break;
      case 505: err.message = 'HTTP版本不受支持(505)'; break;
      default: err.message = `连接出错(${err.response.status})!`;
    }
    if (err.response.status != 404) {
      err.message = err.message + '  ' + msg
    }

  } else {
    err.message = '连接服务器失败!'
  }
  ElMessage.error({ message: err.message })
  return Promise.resolve(err);
})

const app = createApp(App)
app.config.globalProperties.$dateFormat = dateFormat
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(router);
app.component('VueEcharts', ECharts)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.mount('#app');