<template>
  <el-menu
    default-active="1"
    class="el-menu-vertical-demo"
    @select="handleSelect"
  >
    <el-menu-item index="1">
      <template #title>
        <el-icon :size="40">
          <User />
        </el-icon>
        <span>客户管理</span>
      </template>
    </el-menu-item>
    <el-menu-item index="2" v-if="post_mail_visible">
      <el-icon :size="40">
        <document />
      </el-icon>
      <template #title>报告邮件发送</template>
    </el-menu-item>
    <el-menu-item index="3">
      <el-icon :size="40">
        <Histogram />
      </el-icon>
      <template #title>数据统计</template>
    </el-menu-item>
  </el-menu>
</template>
<script>
import { User, Document, Histogram } from "@element-plus/icons-vue";
import GlobalVars from "@/components/GlobalVars.vue";
export default {
  methods: {
    handleSelect(key) {
      this.$emit("menu-change", parseInt(key));
    },
  },
  data() {
    var post_mail_visible = false;
    return {
      User,
      Document,
      Histogram,
      post_mail_visible,
    };
  },
  mounted() {
    // 测试
    this.post_mail_visible = !GlobalVars.post_mail_enabled;
  },
};
</script>

