<script>
// 商务状态
const status = [];
// 客户分类
const category = [];
// 报告分类
const source = [];
// 全部员工
const salesman = [];
// 当前打开的公司ID
const company_id = 0;
// 当前登录的用户ID
const user_id = 0;
// 是否可以发送邮件
var post_mail_enabled = false;
// 是否查看所有人的统计数据
var chart_view_all = false;
export default {
  status,
  source,
  category,
  salesman,
  company_id,
  user_id,
  post_mail_enabled,
  chart_view_all,
};
</script>