<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <MainHeader />
      </el-header>
      <el-container>
        <el-aside width="150px">
          <MenuSide v-on:menu-change="onMenuChange" />
        </el-aside>
        <el-main style="padding-top:0px">
          <el-tabs
            v-model="editableTabsValue"
            type="border-card"
            @tab-remove="removeTab"
            @tab-change="changeTab"
            v-if="MENU_STATUS===1"
          >
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="index"
              :label="item.title"
              :name="item.name"
              :closable='item.closable'
            >
              <component
                :is='item.content'
                :objId='objId'
                v-on:open-detail="addDetailsTab"
                @delTab='delTabs'
              ></component>
            </el-tab-pane>
          </el-tabs>
          <MailPost v-if="MENU_STATUS===2" />
          <Chart v-if="MENU_STATUS===3" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import MainHeader from "@/containers/MainHeader.vue";
import MenuSide from "@/containers/MenuSide.vue";
import CompanyList from "@/containers/CompanyList.vue";
import MailPost from "@/containers/MailPost.vue";
import Chart from "@/containers/ChartView.vue";
import CompanyDetail from "@/containers/CompanyDetail.vue";
import { ref, shallowRef } from "vue";
import { emitter } from "@/libs/bus.ts";
import axios from "axios";
export default {
  components: {
    MainHeader,
    MenuSide,
    CompanyList,
    MailPost,
    CompanyDetail,
    Chart,
  },
  methods: {
    // 切换MENU
    onMenuChange(e) {
      this.MENU_STATUS = e;
    },
    // 加载配置
    async load_config() {
      var url = "api/config/";
      var response = await axios.get(url);
      GlobalVars.status = response.data.status;
      GlobalVars.category = response.data.category;
      GlobalVars.source = response.data.source;
      GlobalVars.post_mail_enabled = response.data.post_mail_enabled;
      GlobalVars.chart_view_all = response.data.chart_view_all;
      GlobalVars.salesman = response.data.salesman;
      GlobalVars.user_id = response.data.user_id;
      emitter.emit("config-complete");
    },
    changeTab(event) {
      GlobalVars.company_id = event;
    },
    // 打开公司详情
    addDetailsTab(id, title) {
      for (var i = 0; i < this.editableTabs.length; i++) {
        if (this.editableTabs[i].name == id) {
          this.editableTabsValue = id;
          return;
        }
      }
      let newTabName = id;
      this.objId = id;
      GlobalVars.company_id = id;
      this.editableTabs.push({
        title: title,
        name: newTabName,
        content: shallowRef(CompanyDetail),
        closable: true,
      });

      this.editableTabsValue = newTabName;
    },
    removeTab(targetName) {
      //--------------这是关闭tab页的
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    btnclick(com, item) {
      //这个子组件点击控制父组件的方法，不同的子组件对应不同的调用
      if (com === "groupInfo") {
        this.ChatMamList();
      } else if (com === "renewGnode") {
        let value = this.editableTabsValue;
        this.addTab(value, com, item);
      } else {
        // this.manageNode();
      }
    },
    delTabs(targetName) {
      //---------------这是子组件想要关闭当前tab页的，控制父组件的操作
      let tabs = this.editableTabs;
      let activeName;
      tabs.forEach((tab, index) => {
        if (tab.title === targetName) {
          //因为判断不一样
          let nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            activeName = nextTab.name;
          }
        }
      });
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.title !== targetName);
      // this.manageNode();
    },
  },
  data() {
    // MENU的状态
    const MENU_STATUS = ref(1);
    return {
      editableTabsValue: 1,
      editableTabs: [
        {
          //第一个默认打开的tabs
          title: "客户列表", //tabs页的名称
          name: 1,
          content: shallowRef(CompanyList), //对应组件名称
          closable: false, // 是否可以关闭，false是不可以，true是可以关闭
        },
      ],
      tabIndex: 1,
      objId: "",
      MENU_STATUS,
    };
  },
  mounted() {
    this.load_config();
  },
};
</script>