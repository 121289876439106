<template>
  <div>
    <el-row align="middle">
      <el-col :span="14">
        <h3>邮件/员工管理</h3>
      </el-col>
      <el-col :span="6">
        <el-form-item
          label=""
          prop="name"
        >
          <el-row>
            <el-col :span="16">
              <el-input
                v-model="staff_search"
                clearable
                @clear="onClearSearch"
              />
            </el-col>
            <el-col
              :span="6"
              :offset="2"
            >
              <el-button
                type="primary"
                @click="onStaffSearch"
              >搜索</el-button>
            </el-col>
          </el-row>

        </el-form-item>
      </el-col>
      <el-col
        :span="4"
        style="text-align: right"
      >
        <el-button
          type="primary"
          @click="onNewStaff"
        >新员工</el-button>
      </el-col>
    </el-row>
    <!-- :default-sort="{ prop: 'name', order: 'descending' }" -->
    <el-table
      border
      :data="StaffData"
      style="width: 100%"
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
      :key="Math.random()"
    >
      <el-table-column
        prop="name"
        label="姓名"
        min-width="60"
      >
      </el-table-column>
      <el-table-column
        prop="mail"
        label="邮件"
        min-width="160"
      >
      </el-table-column>
      <el-table-column
        prop="job"
        label="职务"
        min-width="60"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        min-width="60"
      >
      </el-table-column>
      <el-table-column
        label="数据库权限"
        align="center"
        min-width="60"
      >
        <template #default="scope">
          <el-button
            v-if="scope.row.lib_enabled"
            type="success"
            :icon="Select"
            circle
            size="small"
          />
          <el-button
            v-else
            type="info"
            :icon="CloseBold"
            circle
            size="small"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="邮件推送"
        align="center"
        min-width="60"
      >
        <template #default="scope">
          <el-button
            v-if="scope.row.mail_enabled"
            type="success"
            :icon="Select"
            circle
            size="small"
          />
          <el-button
            v-else
            type="info"
            :icon="CloseBold"
            circle
            size="small"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="微信小程序"
        align="center"
        min-width="60"
      >
        <template #default="scope">
          <el-button
            v-if="scope.row.wechat_enabled"
            type="success"
            :icon="Select"
            circle
            size="small"
          />
          <el-button
            v-else
            type="info"
            :icon="CloseBold"
            circle
            size="small"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="电话会议"
        align="center"
        min-width="60"
      ><template #default="scope">
          <el-button
            v-if="scope.row.call_enabled"
            type="success"
            :icon="Select"
            circle
            size="small"
          />
          <el-button
            v-else
            type="info"
            :icon="CloseBold"
            circle
            size="small"
          />
        </template></el-table-column>
      <el-table-column
        label="备注"
        width="400"
      ><template #default="scope">
          {{scope.row.comment}}
        </template></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            @click="onEditStaff(scope.row.id)"
          >编辑</el-button>
          <el-popconfirm
            title="你确定要删除该员工吗?"
            @confirm="onDeleteStaff(scope.row.id)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
              >删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="currentPage"
      layout="prev, pager, next"
      :total="count"
      :page-size="size"
      @update:current-page="handleCurrentChange"
      style="padding-top:20px"
    />
    <el-dialog
      v-model="StaffVisible"
      title="编辑员工"
      width="600px"
    >
      <el-form label-width="120px">
        <el-form-item label="姓名">
          <el-input
            :disabled="NameLock === 1"
            v-model="staff.name"
          />
        </el-form-item>
        <el-form-item label="邮件">
          <el-input v-model="staff.mail" />
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="职务">
              <el-input v-model="staff.job" />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="电话">
              <el-input v-model="staff.phone" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据库权限">
              <el-switch v-model="staff.lib_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮件推送">
              <el-switch v-model="staff.mail_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信小程序">
              <el-switch v-model="staff.wechat_enabled" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话会议">
              <el-switch v-model="staff.call_enabled" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="staff.comment"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="newStaffRecordVisible = false">关闭</el-button>
          <el-button
            type="primary"
            @click="onStaffEditSubmit"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import axios from "axios";
import { ref, shallowRef } from "vue";
import qs from "qs";
import { ElMessage } from "element-plus";
import { Select, CloseBold } from "@element-plus/icons-vue";
export default {
  data() {
    var StaffData = ref([]);
    var count = 0;
    var currentPage = ref(1);
    var size = 10;
    var StaffVisible = ref(false);
    var staff = ref({});
    var staff_search = "";
    var NameLock = ref(0);
    return {
      Select: shallowRef(Select),
      CloseBold: shallowRef(CloseBold),
      StaffData,
      staff_search,
      count,
      currentPage,
      size,
      StaffVisible,
      staff,
      NameLock,
    };
  },
  methods: {
    onStaffSearch() {
      this.getStaffList(this.company_id, 0);
    },
    onClearSearch() {
      this.getStaffList(this.company_id, 0);
    },
    // 新建员工
    onNewStaff() {
      this.NameLock = 0;
      this.staff = {
        name: "",
        mail: "",
        job: "员工",
        phone: "",
        wechat_enabled: true,
        mail_enabled: true,
        call_enabled: true,
        lib_enabled: true,
        company: this.company_id,
      };
      this.StaffVisible = true;
    },
    // 员工编辑提交
    onStaffEditSubmit() {
      if (this.staff.name == "") {
        ElMessage.error("姓名不能为空");
      } else {
        if (this.NameLock == 1) {
          this.updateStaff(this.staff.id);
        } else {
          this.newStaff(this.company_id);
        }
      }
    },
    // 编辑员工
    onEditStaff(id) {
      this.NameLock = 1;
      for (var i = 0; i < this.StaffData.length; i++) {
        if (id == this.StaffData[i].id) {
          this.staff = this.StaffData[i];
        }
      }
      this.StaffVisible = true;
    },
    // 删除员工
    async onDeleteStaff(id) {
      var url = "api/company/staff/delete/" + id;
      let response = await axios.post(url);
      if (response.status == 204) {
        ElMessage.success("删除成功");
        this.getStaffList(this.company_id, 1);
        this.StaffVisible = false;
      } else {
        ElMessage.error(response.data.msg);
      }
    },
    // 更新员工
    async updateStaff(id) {
      var url = "api/company/staff/update/" + id;
      let response = await axios.post(url, qs.stringify(this.staff));
      if (response.status == 200) {
        ElMessage.success("更新成功");
        this.getStaffList(this.company_id, 1);
        this.StaffVisible = false;
      }
    },
    // 新增员工
    async newStaff(id) {
      var url = "api/company/" + id + "/staff_add";
      let response = await axios.post(url, qs.stringify(this.staff));
      if (response.status == 201) {
        ElMessage.success("新增成功");
        this.getStaffList(this.company_id, 0);
        this.StaffVisible = false;
      } else {
        ElMessage.error(response.data);
      }
    },
    // 获取员工列表
    async getStaffList(id, t) {
      var params = new URLSearchParams();
      if (this.staff_search !== "") {
        params.appendIfExists("search", this.staff_search);
      }
      if (t == 0) {
        params.appendIfExists("page", 1);
        this.currentPage = 1;
      } else {
        params.appendIfExists("page", this.currentPage);
      }
      var paramsString = params.toString();
      var url = "api/company/" + id + "/staff?" + paramsString;
      var response = await axios.get(url);
      this.StaffData = response.data.results;
      this.count = response.data.count;
    },
    // 分页监测
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStaffList(this.company_id, 1);
    },
  },
  mounted() {
    this.company_id = GlobalVars.company_id;
    this.getStaffList(this.company_id, 0);
  },
};
</script>
