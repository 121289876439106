<template>
  <div>
    <el-row align="middle">
      <el-col
        :lg="20"
        :md="16"
      >
        <h3>沟通记录</h3>
      </el-col>
      <el-col
        :lg="4"
        :md="6"
        style="text-align: right"
      >
        <el-button
          type="primary"
          @click="onNewRecord"
        >与新联系人沟通</el-button>
      </el-col>
    </el-row>
    <el-dialog
      v-model="newStaffRecordVisible"
      title="与新联系人沟通"
      width="30%"
    >
      <el-form label-width="120px">
        <el-form-item label="联系人">
          <el-select
            v-model="staff_sel"
            filterable
            class="m-2"
            placeholder="请选择联系人"
            size="large"
          >
            <el-option
              v-for="item in staffs"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="记录内容">
          <el-input
            type="textarea"
            v-model="new_staff_message"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="newStaffRecordVisible = false">关闭</el-button>
          <el-button
            type="primary"
            @click="newStaffRecordSubmit"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-tabs
      style="padding-top:10px"
      :tab-position="tabPosition"
      v-model="TabValue"
      @tab-click="TabClick"
    >
      <el-tab-pane
        v-for="item in Tabs"
        :key="item.name"
        :label="item.title"
        :name="item.name"
      >
        <el-row>
          <el-col
            :lg="18"
            :md="14"
          >
            <el-scrollbar
              ref="scrollbarRef"
              height="400px"
              always
            >
              <el-row>
                <el-col>
                  <el-timeline>
                    <el-timeline-item
                      v-for="(item, index) in recordTimeLine"
                      :key="index"
                      :timestamp="item.modified.substring(0,19).replace('T',' ')"
                    >
                      {{ item.message }}
                    </el-timeline-item>
                  </el-timeline>
                </el-col>
              </el-row>
            </el-scrollbar>
          </el-col>
          <el-col
            :lg="6"
            :md="10"
          >
            <el-card>
              <template #header>
                <div class="card-header">
                  <span>联系人信息</span>
                </div>
              </template>
              <el-descriptions
                border
                direction="vertical"
                style="padding-bottom:20px"
              >
                <el-descriptions-item label="用户名">{{staff.name}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{staff.phone}}</el-descriptions-item>
                <el-descriptions-item label="职务">{{staff.job}}</el-descriptions-item>
                <el-descriptions-item label="邮箱">{{staff.mail}}</el-descriptions-item>
              </el-descriptions>
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  v-model="staff.comment"
                ></el-input>
              </el-form-item>
              <el-row>
                <el-col style="text-align:right">
                  <el-button
                    type="success"
                    @click="onUpdateStaffComment"
                  >更新备注</el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          style="padding-bottom:40px;padding-left:60px;"
          align="middle"
        >
          <el-col :span="12">
            <el-input
              type="textarea"
              v-model="new_record"
            />
          </el-col>
          <el-col :span="2">
            <el-button
              size="large"
              type="primary"
              @click="addRecord"
            >提交</el-button>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import axios from "axios";
import GlobalVars from "@/components/GlobalVars.vue";
import qs from "qs";
import GlobalFunc from "@/libs/GlobalFunc.vue";
import { ElMessage, ElScrollbar } from "element-plus";
export default {
  components: { ElScrollbar },
  data() {
    var tabPosition = "left";
    var Records = [];
    var Tabs = [];
    var TabValue = "";
    var recordTimeLine = [];
    var new_record = "";
    var company_id = 0;
    var user_id = 0;
    var newStaffRecordVisible = false;
    var staffs = [];
    var staff = {};
    var staff_sel = "";
    var new_staff_message = "";
    return {
      recordTimeLine,
      tabPosition,
      Records,
      Tabs,
      TabValue,
      new_record,
      company_id,
      user_id,
      newStaffRecordVisible,
      staffs,
      staff_sel,
      staff,
      new_staff_message,
    };
  },
  methods: {
    async onUpdateStaffComment() {
      let url = "api/company/staff/update/" + this.staff.id;
      let response = await axios.post(url, qs.stringify(this.staff));
      if (response.status === 200) {
        ElMessage.success("更新成功");
      }
    },
    // 与新联系人沟通
    newStaffRecordSubmit() {
      if (this.staff_sel == "" || this.new_staff_message == "") {
        ElMessage.error("请填写全部信息");
      } else {
        if (this.staff_sel == this.TabValue) {
          ElMessage.error("该联系人已存在");
        } else {
          this.addRecord(this.staff_sel, this.new_staff_message);
          this.newStaffRecordVisible = false;
        }
      }
    },
    // 选择新联系人进行沟通
    onNewRecord() {
      if (this.staffs == []) {
        this.getStaffList(this.company_id);
      }
      this.staff_sel = "";
      this.new_staff_message = "";
      this.newStaffRecordVisible = true;
    },
    // 提交新沟通记录
    async addRecord(receiver = 0, msg = "") {
      let url = "api/company/" + this.company_id + "/record_add";
      let form = {};
      form.sender = this.user_id;
      form.done = 1;
      form.way = "T";
      if (receiver == 0) {
        form.receiver = this.TabValue;
        form.message = this.new_record;
      } else {
        if (receiver.isTrusted) {
          form.receiver = this.TabValue;
          form.message = this.new_record;
        } else {
          form.receiver = receiver;
          form.message = msg;
        }
      }
      form.schedule = GlobalFunc.dateFormat("YYYY-mm-dd HH:MM", new Date());
      let response = await axios.post(url, qs.stringify(form));
      if (response != undefined) {
        if (response.status === 201) {
          ElMessage.success("添加记录成功");
          this.new_record = "";
          this.getRecordList(this.company_id);
        }
      }
    },
    // 切换联络人
    TabClick(event) {
      this.getTimeLine(event.props.name);
    },
    // 获取沟通记录列表
    async getRecordList(id) {
      this.Tabs = [];
      var url = "api/company/" + id + "/record";
      var response = await axios.get(url);
      if (response!=undefined) {
        this.Records = response.data.result;
        this.Records.reverse();
        for (var i = 0; i < this.Records.length; i++) {
          this.Tabs.push({
            name: this.Records[i].id,
            title: this.Records[i].receiver,
          });
        }
        if (this.Records.length > 0) {
          if (this.TabValue == "") {
            this.TabValue = this.Records[0].id;
          }
          this.getTimeLine(this.TabValue);
        }
      }
    },
    // 消息滚动条移动到最下方
    scrollDown() {
      for (var i = 0; i < this.$refs.scrollbarRef.length; i++) {
        this.$refs.scrollbarRef[i].setScrollTop(9999);
      }
    },
    // 时间线
    getTimeLine(id) {
      this.recordTimeLine = [];
      for (var i = 0; i < this.Records.length; i++) {
        if (id == this.Records[i].id) {
          for (var j = 0; j < this.Records[i]["records"].length; j++) {
            this.recordTimeLine.push(this.Records[i]["records"][j]);
          }
        }
      }
      for (var k = 0; k < this.staffs.length; k++) {
        if (id == this.staffs[k].id) {
          this.staff = this.staffs[k];
        }
      }
      setTimeout(this.scrollDown, 100);
    },
    async getStaffList(id) {
      this.staffs = [];
      var url = "/api/company/" + id + "/staff_all";
      var response = await axios.get(url);
      this.staffs = response.data.results;
      this.getRecordList(this.company_id);
    },
  },
  mounted() {
    this.user_id = GlobalVars.user_id;
    this.company_id = GlobalVars.company_id;
    this.getStaffList(this.company_id);
  },
};
</script>