<template>
  <el-card style="width:95%">
    <template #header>
      <div>
        <span>数据统计</span>
      </div>
    </template>
    <el-row
      v-if="show_all_enabled"
      align="middle"
    >
      <el-col :span="4">
        <h4>统计类别</h4>
      </el-col>
      <el-col :span="8">
        <el-radio-group
          v-model="show_type"
          size="large"
        >
          <el-radio-button label="公司" />
          <el-radio-button label="个人" />
        </el-radio-group>
      </el-col>
      <el-col :span="4">
        <h4>统计时间</h4>
      </el-col>
      <el-col :span="8">
        <el-radio-group
          size="large"
          v-model="range"
        >
          <el-radio-button
            v-for="item in date_range"
            :label="item.id"
            :key="item.id"
          >{{item.name}}</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row
      v-if="show_type=='个人' & show_all_enabled"
      align="middle"
    >
      <el-col :span="4">
        <h4>要查看的员工</h4>
      </el-col>
      <el-col :span="18">
        <el-select
          v-model="salesman_sel"
          class="m-2"
          placeholder="请选择"
          size="large"
        >
          <el-option
            v-for="item in salesman"
            :key="item.id"
            :label="item.full_name"
            :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row
      v-if="!show_all_enabled"
      align="middle"
    >
      <el-col :span="4">
        <h4>统计时间</h4>
      </el-col>
      <el-col :span="8">
        <el-radio-group
          size="large"
          v-model="range"
        >
          <el-radio-button
            v-for="item in date_range"
            :label="item.id"
            :key="item.id"
          >{{item.name}}</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row
      align="middle"
      justify="end"
    >
      <el-col :span="4">
        <el-button
          type="primary"
          @click="onStatSearch"
        >查询</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="12">
        <v-chart
          :option="company_all"
          style="height: 400px"
        ></v-chart>
      </el-col>
      <el-col :span="12">
        <v-chart
          :option="company_deal_all"
          style="height: 400px"
        ></v-chart>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <v-chart
          :option="record_all"
          style="height: 400px"
        ></v-chart>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import GlobalVars from "@/components/GlobalVars.vue";
import axios from "axios";
import ECharts from "vue-echarts";
import { ElMessage } from "element-plus";
export default {
  components: {
    "v-chart": ECharts,
  },
  data() {
    var show_all_enabled = false;
    var show_type = "公司";
    var range = 0;
    var salesman_sel = "";
    var salesman = [];
    var date_range = [
      { name: "全部", id: 0 },
      { name: "年度", id: 1 },
      { name: "季度", id: 2 },
      { name: "月度", id: 3 },
    ];
    var salesman_stat = [];
    var company_all = {};
    var record_all = {};
    var company_deal_all = {};
    return {
      show_all_enabled,
      show_type,
      range,
      salesman_sel,
      salesman,
      salesman_stat,
      company_all,
      company_deal_all,
      record_all,
      date_range,
    };
  },
  methods: {
    // 查询统计
    onStatSearch() {
      if (this.show_all_enabled) {
        if (this.show_type == "公司") {
          this.getSalesmanStat();
        } else {
          if (this.salesman_sel == "") {
            ElMessage.error("请选择要查看的员工");
          } else {
            this.getSalesmanStat(this.salesman_sel);
          }
        }
      } else {
        this.getSalesmanStat(0);
      }
    },
    // 获取销售员统计
    async getSalesmanStat(id = "") {
      var url = "api/chart/salesman_stat/" + id;
      url += "?range=" + this.range;
      var response = await axios.get(url);
      this.salesman_stat = response.data;
      this.company_all = {
        title: {
          text:
            "[" +
            this.salesman_stat.salesman +
            "]" +
            "新增客户共计" +
            this.salesman_stat.company_count_all +
            "家",
        },
        tooltip: {},
        xAxis: {
          data: this.salesman_stat.company_all_x,
        },
        yAxis: {},
        series: [
          {
            name: "新增客户",
            type: "bar",
            data: this.salesman_stat.company_all_y,
          },
        ],
      };
      this.company_deal_all = {
        title: {
          text:
            "[" +
            this.salesman_stat.salesman +
            "]" +
            "新增成交共计" +
            this.salesman_stat.company_count_deal +
            "元",
        },
        tooltip: {},
        xAxis: {
          data: this.salesman_stat.company_deal_x,
        },
        yAxis: {},
        series: [
          {
            name: "新增成交",
            type: "bar",
            data: this.salesman_stat.company_deal_y,
          },
        ],
      };
      this.record_all = {
        title: {
          text: "[" + this.salesman_stat.salesman + "]" + "新增联络统计",
        },
        tooltip: {},
        xAxis: {
          data: this.salesman_stat.record_x,
        },
        yAxis: {},
        series: [
          {
            name: "新增联络",
            type: "bar",
            data: this.salesman_stat.record_y,
          },
        ],
      };
    },
    // 获取销售员
    async getSalesman() {
      var url = "api/chart/salesman";
      var response = await axios.get(url);
      this.salesman = response.data.results;
      // this.salesman.splice(0, 0, { full_name: "全部", id: -1 });
    },
  },
  mounted() {
    this.show_all_enabled = GlobalVars.chart_view_all;
    if (this.show_all_enabled) {
      this.getSalesman();
    }
  },
};
</script>